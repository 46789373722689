import {Component, OnInit} from '@angular/core';
import {ActionSheetController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {
  ATTENDANCE_ID_STORAGE_KEY,
  ATTENDANCE_PAGE,
  ATTENDANCE_STATUS, CLIENT_USERNAME_STORAGE_KEY, CURRENT_ATTENDANCE_STATUS, ESPANHOL_LANGUAGE,
  HOME_PAGE, ROOM_NAME_STORAGE_KEY,
  STORAGE_KEYS,
  SUPPORTED_LANGUAGES
} from './app.constants';
import {StorageService} from './services/storage.service';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';
import {fromEvent, merge, Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';
import {AttendanceService} from './services/attendance.service';
import { Location } from '@angular/common';
import { LoadingService } from './services/loading.service';
import { AttendanceStatus } from './models/attendance-status';

let apiLoaded = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isOnline = true;
  lastConnectionStatusChange: number = new Date().getTime();
  finishConfirmActionSheet;
  navigatorWrapper: any = window.navigator;

  constructor(
      private readonly platform: Platform,
      private readonly splashScreen: SplashScreen,
      private readonly statusBar: StatusBar,
      private readonly translate: TranslateService,
      private readonly storageService: StorageService,
      private readonly router: Router,
      private readonly modalController: ModalController,
      private readonly translateService: TranslateService,
      private readonly actionSheetController: ActionSheetController,
      private readonly attendanceService: AttendanceService,
      private readonly location: Location,
      private readonly loadingService: LoadingService
  ) {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
    this.accepted = Boolean(
        this.storageService.get(STORAGE_KEYS.ACCEPTED_TERMS)
    );
    this.createOnline$().subscribe(isOnline => {
      modalController.getTop().then((res: any) => {
        if (res) {
          modalController.dismiss();
        }
      });
      if (!isOnline) {
        this.isOnline = false;
      } else if (!this.isOnline) {
        this.isOnline = true;
        window.location.reload();
      } else {
        this.isOnline = true;
      }
      this.lastConnectionStatusChange = new Date().getTime();
    });
    this.storageService.set('APP_VERSION', environment.VERSION);
    this.initializeApp();
  }

  accepted = false;

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.configureTranslation();
      this.initTranslate();
      this.handleAbandonedCall();
    });
  }

  private configureTranslation() {
    this.translate.addLangs(SUPPORTED_LANGUAGES);
    this.translate.setDefaultLang(ESPANHOL_LANGUAGE);
  }

  initTranslate() {
    this.translate.use(
        this.storageService.get(STORAGE_KEYS.LANGUAGE) || ESPANHOL_LANGUAGE
    );
  }

  ngOnInit() {
    const navigateToHome = sessionStorage.getItem('navigateToHome');
    if (navigateToHome === 'true') {
      this.router.navigateByUrl(HOME_PAGE);
      localStorage.removeItem('navigateToHome');
    }
  }

  createOnline$() {
    return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
  }

  handleAbandonedCall() {
    const attendanceId = localStorage.getItem(ATTENDANCE_ID_STORAGE_KEY);
    if (attendanceId && attendanceId !== 'undefined') {
      this.attendanceService.getStatus(attendanceId).subscribe((attendanceStatus) => {
        if (attendanceStatus.status !== ATTENDANCE_STATUS.FINISHED) {
          if ((this.location.path() !== ATTENDANCE_PAGE.toLowerCase()) 
            || this.needUserInteraction(attendanceStatus)) {
            this.createFinishConfirmActionSheet(attendanceId);
          }
        } else {
          this.removeLastChannel();
        }
      });
    }
  }

  async createFinishConfirmActionSheet(attendanceId) {
    this.finishConfirmActionSheet = await this.actionSheetController.create({
      backdropDismiss: false,
      cssClass: 'eps-action-sheet',
      mode: 'ios',
      header: this.translateService.instant('home.actionSheet.returnToApp.header'),
      buttons: [
        {
          text: this.translateService.instant('home.actionSheet.returnToApp.cancel'),
          role: 'cancel',
          handler: async () => {
            if (this.location.path() === ATTENDANCE_PAGE.toLowerCase()) {
              this.loadingService.show();
            }
            this.attendanceService.finishAttendance(attendanceId,
                'Customer::AppComponent::createFinishConfirmActionSheet::' + attendanceId).subscribe(() => {
              this.removeLastChannel();
            }, (error) => {
              console.log(error);
              this.removeLastChannel();
            });
          }
        },
        {
          text: this.translateService.instant('home.actionSheet.returnToApp.return'),
          role: 'destructive',
          cssClass: 'finish-button',
          handler: async () => {
            this.router.navigateByUrl(ATTENDANCE_PAGE);
          }
        }
      ]
    });
    await this.finishConfirmActionSheet.present();
  }

  removeLastChannel() {
    localStorage.removeItem(ATTENDANCE_ID_STORAGE_KEY);
    localStorage.removeItem(ROOM_NAME_STORAGE_KEY);
    localStorage.removeItem(CLIENT_USERNAME_STORAGE_KEY);
    localStorage.removeItem(CURRENT_ATTENDANCE_STATUS);
  }

  needUserInteraction(attendanceStatus: AttendanceStatus) {
    return !this.navigatorWrapper.userActivation.isActive 
      && attendanceStatus.status === ATTENDANCE_STATUS.IN_PROGRESS;
  }
}
