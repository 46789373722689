/**
 * Constants of language app
 */
export const PORTUGUESE_LANGUAGE = 'pt-br';
export const ENGLISH_LANGUAGE = 'en';
export const ESPANHOL_LANGUAGE = 'es';
export const DEFAULT_LANGUAGE = PORTUGUESE_LANGUAGE;
export const SUPPORTED_LANGUAGES = [PORTUGUESE_LANGUAGE, ENGLISH_LANGUAGE, ESPANHOL_LANGUAGE];

/**
 * Constants of PAGES
 */
export const HOME_PAGE = '/home';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions';
export const PHONE_CALL_PAGE = '/phone-call';
export const CHATBOT_PURCHASE_PAGE = '/chatbot-purchase';
export const CHATBOT_SUPPORT_PAGE = '/chatbot-support';
export const PRODUCT_REGISTRATION_PAGE = '/product-registration';
export const INFORMATION_PAGE = '/information';
export const ATTENDANCE_PAGE = '/attendance';
export const INIT_ATTENDANCE = '/init-attendance';
export const INIT_CHATBOT_PAGE = '/init-chatbot';
export const PRODUCT_DETAIL_PAGE = '/product-detail';
export const PRODUCT_COMPARE_PAGE = '/product-compare';
export const SPLASH_PAGE = '/splash';
export const SESSION_FEEDBACK_PAGE = '/session-feedback';
export const SCHEDULE_PAGE = '/schedule';
export const THANKS_PAGE = '/thanks';

export const ATTENDANCE_TYPE = {
  VIDEO: 'VIDEO',
  CHAT: 'CHAT',
};

/**
 * Constants of types of user
 */
export const USER_TYPE = {
  CHATBOT: 'chatbot',
  USER: 'user',
  ATTENDANT: 'attendant'
};

export const VIDEO_ROOM_PREFIX_SUPERVISOR = 'SUPERVISOR';

/**
 * Constants of Chat Page
 */
export const MAX_LENGTH_CHAT_TEXT = 2048;
export const MAX_LENGTH_CHATBOT_TEXT = 150;
export const TIMEOUT_INACTIVITY_CHAT = 300000;
export const TIMEOUT_INACTIVITY_CHAT_TOAST = 240000;
export const ATTENDANCE_NICKNAME_MIN_LENGTH = 3;
export const ATTENDANCE_NICKNAME_MAX_LENGTH = 50;
export const MAXIMUM_ITEMS_THRESHOLD_TO_RESET_CONSTANTS = 3;
export const EMPTY_LIST = 0;

/**
 * Constants of Video Page
 */
export const TIMEOUT_VIDEO = 3600000;
export const TIMEOUT_VIDEO_TOAST = 1800000;
export const TIMEOUT_INACTIVITY_VIDEO = 300000;


export const CHAT_MESSAGE_FONT_SIZE = {
  MAX: 1.4,
  MIN: 0.9,
};

export const CHATBOT_INITIAL_MESSAGES = [
  'chatbot.messages.chatbotInit1',
  'chatbot.messages.chatbotInit2',
  'chatbot.messages.chooseProduct',
  'chatbot.messages.chooseProductModel',
];


/**
 * Constants of headers
 */
export const HEADER_KEY_TOKEN = 'x-watson-session';
export const ACCEPT_LANGUAGE = 'Accept-Language';

/**
 * Constants of PAGES
 */
export const CHAT_PAGE = '/chat-page';
export const INIT_CHAT_PAGE = '/init-chat';
export const LAST_ATTENDANCES_PAGE = '/last-attendance';

/**
 * Constants of API Routes
 */
export const CONVERSATION_HISTORY_API = '/conversation-history';
export const AUTHENTICATION_API = '/authenticate';


/**
 * Constants of storage
 */
export const PRODUCT_SERIAL = 'product-serial';
export const PRODUCT_PREFIX = 'product-prefix';
export const AUTHENTICATION_TOKEN = 'authenticationToken';

/**
 * Constants of network
 */
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const CONNECTION_STATUS = 'connectionStatus';


export const MESSAGES_DEFAULT_CHATBOT = {
  WELCOME: ['chat.messages.chatbotInit1', 'chat.messages.chatbotInit2', 'chat.messages.chatbotInit3'],
  ERROR: ['chat.messages.chatbotFailure', 'chat.messages.chatbotFailure2', 'chat.messages.chatbotFailure3']
};

export const STORAGE_KEYS = {
  ACCEPTED_TERMS: 'acceptedTerms',
  TENANT_ID: 'tenantId',
  LANGUAGE: 'language',
  TERMS: 'terms',
  TERMS_SELECT: 'terms-select',
  COUNTRY_ISO_CODE: 'countryIsoCode',
  UTM_SOURCE: 'utm_source',
  PRIVACY_POLICY: 'privacyPolicy',
  DATA_PRIVACY: 'dataPrivacy',
  COOKIES: 'cookies',
  TENANT_NOT_FOUND: 'TENANT_NOT_FOUND',
  CLIENT_RANDOM_IDENTITY: 'clientRandomIdentity',
  CLIENT_NICKNAME: 'clientUsername'
};

export const PRODUCT = {
  MIN_LENGTH_MODEL_REF: 10,
  MAX_LENGTH_MODEL_REF: 11
};

export const ADS_TYPES = {
  IMAGE: 1,
  YOUTUBE: 2,
  VIDEO: 3
};

export const ATTENDANCE_STATUS = {
  OPENED: 'OPENED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED'
};

/**
 * Constants of Audio Alert
 */
export const AUDIO_RECEIVE_MESSAGE = '../assets/audio/receive-message.wav';

/**
 * Constants of product errors
 */

export const INVALID_SERIAL = 'error.invalid-serial';

/**
 * Constants for youtube links
 */
export const YOUTUBE_URLS = {
  YOUTUBE_COM: 'youtube.com',
  YOUTU_BE: 'youtu.be',
  YOUTUBE_NOCOOKIE: 'youtube-nocookie.com'
};

export const UTM_SOURCE_MAX_LENGTH = 100;

/**
 * Constants of attendance
 */
export const ATTENDANCE_ID_STORAGE_KEY = 'ATTEDANCE_ID';
export const ROOM_NAME_STORAGE_KEY = 'ATTEDANCE_ROOM_NAME';
export const CLIENT_USERNAME_STORAGE_KEY = 'clientUsername';
export const SESSION_TYPE_CHAT = 'CHAT';
export const SESSION_TYPE_VIDEO = 'VIDEO';
export const SESSION_TYPE = 'SESSION_TYPE';
export const CURRENT_ATTENDANCE_STATUS = 'CURRENT_ATTENDANCE_STATUS';
export namespace ATTENDANCE_LOG_TYPES {
  export const DEBUG: 'DEBUG' = 'DEBUG';
  export const INFO: 'INFO' = 'INFO';
  export const ERROR: 'ERROR' = 'ERROR';
};

/**
 * Constants of platform
 */
export const DESKTOP_VALUE = 'desktop';
export const PLATFORM_IOS_VALUE = 'ios';

export const NETWORK_MANAGEMENT_CONSTANTS = {
  ONE_MINUTE_IN_MILLISECONDS: 60000,
  CUSTOMER_OFFLINE_TIMEOUT_MINUTES: 1
}

/**
 * Constants Attendance
 */
export const ATTENDANCE_PREFIX = {
  ATTENDANT: 'ATTENDANT_',
  SUPERVISOR: 'SUPERVISOR_',
  CUSTOMER: 'CUSTOMER_'
};

export const ATTENDANCE_TYPES_MESSAGE = {
  LINK: 0,
  TEXT: 1,
  PDF: 2,
  IMAGE: 3,
  VIDEO: 4,
  LOADING_UPLOAD: 5,
  DELETED: 6
};

/**
 * Constants Streaming Events
 */
export const STREAMING_EVENTS = {
  CHAT_ON_MESSAGE: 'chat-on-message',
  USER_ADDED: 'user-added',
  USER_UPDATED: 'user-updated',
  USER_REMOVED: 'user-removed',
  COMMAND_CHANNEL_MESSAGE: 'command-channel-message',
  PEER_VIDEO_STATE_CHANGE: 'peer-video-state-change',
  VIDEO_ACTIVE_CHANGE: 'video-active-change',
  COMMAND_CHANNEL_STATUS: 'command-channel-status',
  CONNECTION_CHANGE: 'connection-change'
};

export const ZOOM_VIDEO_ACTIVE_STATES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const COMMAND_EVENTS = {
  CUSTOMER_LEFT: 'customer-left',
  NETWORK_STATUS: 'network-status',
  TYPING_START: 'typing-start',
  TYPING_END: 'typing-end'
};

export const COMMAND_TYPE_EVENTS = {
  BROADCAST: 'broadcast',
  UNICAST: 'unicast'
};

export const COMMAND_STATUS = {
  CONNECTED: 'Connected',
  CLOSED: 'Closed',
  FAIL: 'Fail',
  RECONNECTING: 'Reconnecting'
};

export const ZOOM_VIDEO_STATE = {
  STOP: 'Stop',
  START: 'Start'
};

export const TYPING_TIMEOUT_MS = 5000;
export const CHECK_MY_ATTENDANCE_TIMEOUT_MS = 5000;
export const DISCOVERY_PROMOTER_TIMEOUT_MS = 3000;
export const CHECK_NODE_ID_TIMEOUT_MS = 3000;
export const MESSAGE_PER_PAGE = 50;
export const DEFAULT_MESSAGE_PER_PAGE_REFRESH = 2000;
export const DEFAULT_INITIAL_PAGE = 0;

/**
 * Constants Meeting State
 */
export const MEETING_STATE = {
  OPEN: 'open',
  CLOSED: 'closed'
};

export const SURVEY_STATUS = {
  FOUND: 'FOUND'
};

export const FILE_STACK_STATUS = {
  FINISHED: 'FINISHED'
};

/**
 * Constants for Filestack
 */
export namespace FilestackConstants {
  export const CDN_URL = 'https://cdn.filestackcontent.com/';
  export const WORKFLOW_STATUS_FINISHED = 'finished';
  export const WORKFLOW_TASK_STATUS_DELETED = 'deleted';
  export const WORKFLOW_CHECK_TIMEOUT_MS = 10000;
  export const DESCRIPTION_FILE_DELETED = `DELETED FILESTACK FILE`;
  export const FILESTACK_POLICY_EXPIRATION_MS = 10800000;
}

export const TIME_CONSTANTS = {
  MILLISECOND: 1,
  get SECOND() { return 1000 * this.MILLISECOND },
  get MINUTE() { return 60 * this.SECOND },
  get HOUR() { return 60 * this.MINUTE },
  get DAY() { return 24 * this.HOUR },
  get WEEK() { return 7 * this.DAY },
  get MONTH() { return 30 * this.DAY },
  get YEAR() { return 365 * this.DAY }
}
