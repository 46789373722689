import { IQueuePosition } from './../models/attendance';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAttendance } from '../models/attendance';
import { createRequestOption } from '../shared/util/request-util';
import { AttendanceStatus } from '../models/attendance-status';
import {environment} from '../../environments/environment';
import {IMessage} from './streams/streamingChannel.model';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  private resourceUrl = `${environment.API_BASE_URL}/attendances`;
  emitAttendanceFinished = new EventEmitter<any>();

  constructor(private http: HttpClient) { }

  getAllAttendancesOpenedByType(
    req?: any
  ): Observable<HttpResponse<IAttendance[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAttendance[]>(`${this.resourceUrl}/list/opened`, {
      params: options,
      observe: 'response',
    });
  }

  getAllAttendancesOpenedByTypeAndAttendanceStart(
    req?: any
  ): Observable<HttpResponse<IAttendance[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAttendance[]>(`${this.resourceUrl}/video/list/opened`, {
      params: options,
      observe: 'response',
    });
  }

  getById(id: string): Observable<IAttendance> {
    return this.http.get<IAttendance>(`${this.resourceUrl}/${id}`);
  }

  startAttendance(attendance: IAttendance) {
    return this.http.post(`${this.resourceUrl}/start`, attendance);
  }

  saveMessage(messageChat: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/message`, messageChat);
  }

  changeStatusToInProgress(id): Observable<IAttendance> {
    return this.http.patch<IAttendance>(`${this.resourceUrl}/in-progress/${id}`, {});
  }

  // New services
  create(attendance): Observable<IAttendance> {
    return this.http.post<IAttendance>(this.resourceUrl, attendance);
  }

  query(req?: any): Observable<HttpResponse<IAttendance[]>> {
    const options = createRequestOption(req);
    return this.http.get<IAttendance[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  updateAttedanceStart(id) {
    return this.http.patch(`${this.resourceUrl}/${id}/update-attendanceStart`, {});
  }

  find(id: string): Observable<IAttendance> {
    return this.http.get<IAttendance>(`${this.resourceUrl}/${id}`);
  }

  // TODO (02/05/2024): Depreciated
  getAccessToken(id, nickname?): Observable<any> {
    return this.http.get(`${this.resourceUrl}/access_token/attendant/${id}?nickname=${nickname}`);
  }

  getAccessTokenCustomer(id): Observable<any> {
    return this.http.get(`${this.resourceUrl}/${id}/access-token/customer`);
  }

  finishAttendance(id, origin: string = '') {
    const options = createRequestOption({origin});
    return this.http.patch(`${this.resourceUrl}/finish/${id}`, {}, {params: options});
  }

  startAttendanceChat(attendance): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/chat-start`, attendance);
  }

  verifyAttendanceStatus() {
    return this.http.get(`${this.resourceUrl}/verify`);
  }

  getAllAttendantsByType(type): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(`${this.resourceUrl}/attendants`, { params: { type }, observe: 'response' });
  }

  setConversatinSID(conversationSID: string, attendanceId: number) {
    return this.http.put(`${this.resourceUrl}/conversation`, { conversationSID, attendanceId });
  }

  getPositionAttendance(id){
    return this.http.get<IQueuePosition>(`${this.resourceUrl}/position/${id}`);
  }

  getMessages(id, params): Observable<any> {
    const options = createRequestOption(params);
    return this.http.get<any>(`${this.resourceUrl}/messages/${id}`, { params: options, observe: 'response' });
  }

  getStatus(id) {
    return this.http.get<AttendanceStatus>(`${this.resourceUrl}/${id}/status`);
  }

  getMessageStatus(id: number): Observable<IMessage> {
    return this.http.get<IMessage>(`${this.resourceUrl}/message-chat/${id}`);
  }

  updateMessage(workflowJobId, message: any, type): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/message`, {workflowJobId, message, type});
  }
}
