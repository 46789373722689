import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ATTENDANCE_ID_STORAGE_KEY, ATTENDANCE_PAGE, ATTENDANCE_STATUS, SCHEDULE_PAGE } from '../app.constants';
import {ScheduleService} from '../services/schedule.service';
import { AttendanceService } from '../services/attendance.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly scheduleService: ScheduleService,
    private readonly attendanceService: AttendanceService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.checkIfMustRedirectToSchedulePage();
    return true;
  }

  checkIfMustRedirectToSchedulePage() {
    const attendanceId = localStorage.getItem(ATTENDANCE_ID_STORAGE_KEY);
    if (attendanceId && attendanceId !== 'undefined') {
      this.attendanceService.getStatus(attendanceId).subscribe((attendanceStatus) => {
        if (attendanceStatus.status === ATTENDANCE_STATUS.FINISHED) {
          this.checkScheduleAndRedirect();
        }
      });
    } else {
      this.checkScheduleAndRedirect();
    }
  }

  checkScheduleAndRedirect() {
    this.scheduleService.checkSchedule().subscribe((res: any) => {
      if (!res.online) {
        this.router.navigateByUrl(SCHEDULE_PAGE);
      }
    }, (error: any) => {
      console.log('Could not check schedule.', error);
    });
  }
}
