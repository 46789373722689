import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TenantGuard} from './guards/tenant.guard';
import {ScheduleGuard} from './guards/schedule.guard';
import { DisableBackGuard } from './guards/disable-back.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
  {
    path: 'phone-call',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/phone-call/phone-call.module').then(
        (m) => m.PhoneCallPageModule
      ),
  },
  {
    path: 'chatbot-purchase',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/chatbot/chatbot.module').then(
        (m) => m.ChatbotPageModule
      ),
  },
  {
    path: 'product-registration',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/product-registration/product-registration.module').then(
        (m) => m.ProductRegistrationPageModule
      ),
  },
  {
    path: 'chatbot-support',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/chat-page/chat-page.module').then(
        (m) => m.ChatPagePageModule
      ),
  },
  {
    path: 'information',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/information/information.module').then(
        (m) => m.InformationPageModule
      ),
  },
  {
    path: 'attendance',
    canActivate: [TenantGuard, ScheduleGuard],
    canDeactivate: [DisableBackGuard],
    loadChildren: () =>
      import('./pages/live-chat-detail/live-chat-detail.module').then(
        (m) => m.LiveChatDetailPageModule
      ),
  },
  {
    path: 'schedule',
    canActivate: [],
    loadChildren: () =>
        import('./pages/schedule/schedule.module').then(
            (m) => m.ScheduleModule
        ),
  },
  {
    path: 'product-detail',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/product-detail/product-detail.module').then(
        (m) => m.ProductDetailPageModule
      ),
  },
  {
    path: 'init-attendance',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/init-live-chat/init-live-chat.module').then(
        (m) => m.InitLiveChatPageModule
      ),
  },
  {
    path: 'splash',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/splash/splash.module').then(
        (m) => m.SplashPageModule
      ),
  },
  {
    path: 'terms-and-conditions',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/terms/terms.module').then(
        (m) => m.TermsPageModule
      ),
  },
  {
    path: 'product-media-view',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/product-media-view/product-media-view.module').then(
        (m) => m.ProductMediaViewPageModule
      ),
  },
  {
    path: 'product-datasheet',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/product-datasheet/product-datasheet.module').then(
        (m) => m.ProductDatasheetPageModule
      ),
  },
  {
    path: 'product-compare',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () =>
      import('./pages/product-compare/product-compare.module').then(
        (m) => m.ProductComparePageModule
      ),
  },
  {
    path: 'decline',
    loadChildren: () => import('./pages/decline/decline.module').then(
      (m) => m.DeclinePageModule
    )
  },
  {
    path: 'session-feedback',
    canActivate: [TenantGuard],
    loadChildren: () => import('./pages/session-feedback/session-feedback.module').then(
      (m) => m.SessionFeedbackPageModule
    )
  },
  {
    path: 'init-chatbot',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () => import('./pages/init-chatbot/init-chatbot.module').then(
      (m) => m.InitChatbotPageModule
    )
  },
  {
    path: 'thanks',
    canActivate: [TenantGuard, ScheduleGuard],
    loadChildren: () => import('./pages/thanks/thank.module').then(
      (m) => m.ThankPageModule
    )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
