import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TenantService } from '../services/tenant.service';
import { HOME_PAGE, SPLASH_PAGE, STORAGE_KEYS, TERMS_AND_CONDITIONS_PAGE, UTM_SOURCE_MAX_LENGTH } from '../app.constants';
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TenantGuard implements CanActivate {

  constructor(
    private tenantService: TenantService,
    private router: Router,
    private readonly storageService: StorageService,
    private translate: TranslateService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hasAcceptedTermsAndConditions = this.storageService.get(STORAGE_KEYS.ACCEPTED_TERMS);
    const tenantSelect = this.storageService.get(STORAGE_KEYS.TERMS_SELECT);
    let response;

    if (next.queryParams && next.queryParams.utm_source !== undefined) {
      const utmSource = next.queryParams.utm_source
        .substring(0, Math.min(next.queryParams.utm_source.length, UTM_SOURCE_MAX_LENGTH));
      this.storageService.setSession(STORAGE_KEYS.UTM_SOURCE, utmSource);
    }

    if (next.queryParams && next.queryParams.country) {
      const countryIsoCode = this.storageService.get(STORAGE_KEYS.COUNTRY_ISO_CODE);

      if (countryIsoCode === next.queryParams.country) {
        if (this.storageService.get(STORAGE_KEYS.ACCEPTED_TERMS)) {
          this.router.navigateByUrl(HOME_PAGE);
        } else {
          this.router.navigateByUrl(TERMS_AND_CONDITIONS_PAGE);
        }
        return true;
      }
      this.tenantService.clearTenant();
    }

    if (this.tenantService.getTenant()) {
      this.tenantService.findActivatedTrue().subscribe((res: any) => {
        const tenantFound = res.find((tenant: any) => tenant.id === Number(this.tenantService.getTenant()));
        if (tenantFound) {
          this.translate.use(tenantFound.language);
          localStorage.setItem(STORAGE_KEYS.LANGUAGE, tenantFound.language);
        } else {
          this.tenantService.clearTenant();
          this.router.navigateByUrl(SPLASH_PAGE, {  });
        }
      });
    }

    if (!this.tenantService.getTenant()) {
      this.tenantService.findActivatedTrue().subscribe((res: any) => {
        if (next.queryParams && next.queryParams.country) {
          response = res.find((tenant) => tenant.countryIsoCode === next.queryParams.country);
        }
        if (response) {
          this.tenantService.setTenant(response);
          this.router.navigateByUrl(TERMS_AND_CONDITIONS_PAGE);
          this.storageService.set(STORAGE_KEYS.TENANT_NOT_FOUND, 'false');
        } else {
          this.storageService.set(STORAGE_KEYS.TENANT_NOT_FOUND, 'true');
          if (state.url !== SPLASH_PAGE) {
            this.router.navigateByUrl(SPLASH_PAGE, {  });
          }
        }
      });
    } else if (state.url !== TERMS_AND_CONDITIONS_PAGE && !hasAcceptedTermsAndConditions && tenantSelect) {
      this.router.navigateByUrl(TERMS_AND_CONDITIONS_PAGE);
    } else if (state.url === SPLASH_PAGE && this.storageService.get(STORAGE_KEYS.ACCEPTED_TERMS)) {
      this.router.navigateByUrl(HOME_PAGE);
    }
    return true;
  }
}
